<template>
  <div class="container-fluid">
    <b-modal
      ref="post-delivery-note-modal"
      size="lg"
      :title="$t('deliveryNoteMsgs.manageDeliveryNote')"
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12" v-if="order">
                  <label for="order">{{ $t("orderMsgs.order") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="order"
                    :value="order.order_number"
                    disabled
                  />
                </div>

                <div class="form-group col-md-12" v-if="deliveryNoteId">
                  <label for="noteNum">{{
                    $t("deliveryNoteMsgs.noteNum")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="noteNum"
                    :placeholder="$t('deliveryNoteMsgs.noteNum')"
                    v-model="noteNum"
                    required
                    disabled
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="date">{{ $t("date") }}</label>

                  <b-form-datepicker
                    v-model="date"
                    :locale="getCurrLanguage"
                    required
                  ></b-form-datepicker>
                </div>

                <div class="form-group col-md-12">
                  <label for="workMonth">{{
                    $t("deliveryNoteMsgs.workMonth")
                  }}</label>

                  <b-form-select
                    id="workMonth"
                    class="form-control form-control-b-select"
                    v-model="workMonth"
                    required
                    :options="allMonthsOptions"
                  >
                  </b-form-select>
                </div>
              </div>

              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isLoading"
                  style="min-width: 100px"
                >
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{
                    deliveryNoteId ? $t("update") : $t("add")
                  }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { get, head } from "lodash";

export default {
  name: "PostDeliveryNoteModal",

  props: {
    showModal: Boolean,
    orderId: Number,
    order: Object,
  },

  data() {
    return {
      noteNum: "",
      date: moment().format("YYYY-MM-DD"),
      workMonth: moment().month() + 1, // +1 used for converting 0-11 based month to 1-12 based.
      deliveryNote: null,
      deliveryNoteId: null,

      isLoading: false,
    };
  },

  methods: {
    ...mapActions([
      "getDeliveryNotes",
      "createDeliveryNote",
      "updateDeliveryNote",
      "getOrder",
    ]),

    resetData() {
      this.date = moment().format("YYYY-MM-DD");

      // +1 used for converting 0-11 based month to 1-12 based.
      this.workMonth = moment().month() + 1;

      this.deliveryNote = this.deliveryNoteId = this.noteNum = null;
      this.isLoading = false;
    },

    hideModal() {
      this.$emit("close");
      this.resetData();
    },

    hideModalManual() {
      this.$refs["post-delivery-note-modal"].hide();
      this.hideModal();
    },

    hideWithUpdateModal() {
      this.$refs["post-delivery-note-modal"].hide();
      this.$emit("closeUpdate");
      this.resetData();
    },

    async fetchDeliveryNote() {
      this.isLoading = true;

      try {
        const note = (await this.getDeliveryNotes({ order: this.orderId }))
          .data;

        if (get(note, "length")) {
          this.deliveryNote = head(note);
          this.deliveryNoteId = this.deliveryNote.id;
          this.noteNum = this.deliveryNote.delivery_note_number;
          this.date = this.deliveryNote.date;
          this.workMonth = this.deliveryNote.work_month;
        }
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        this.hideModalManual();
      }

      this.isLoading = false;
    },

    async onSubmit() {
      // TODO: Add validations.

      this.isLoading = true;

      try {
        const noteData = {
          order: this.orderId,
          date: this.date,
          work_month: this.workMonth,
          // TODO: Uncomment this after adding unique check.
          // ...(this.noteNum && { delivery_note_number: this.noteNum }),
        };
        if (this.deliveryNoteId) {
          await this.updateDeliveryNote({
            id: this.deliveryNoteId,
            data: noteData,
          });
          this.makeToast(
            "success",
            this.$t("deliveryNoteMsgs.deliveryNoteUpdated")
          );
        } else {
          await this.createDeliveryNote(noteData);
          this.makeToast(
            "success",
            this.$t("deliveryNoteMsgs.deliveryNoteAdded")
          );
        }
        this.isLoading = false;
        // this.hideWithUpdateModal();
        this.hideModalManual();
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {
    ...mapGetters(["getCurrLanguage"]),

    allMonthsOptions() {
      return [
        { value: 1, text: this.$t("monthNames.jan") },
        { value: 2, text: this.$t("monthNames.feb") },
        { value: 3, text: this.$t("monthNames.march") },
        { value: 4, text: this.$t("monthNames.april") },
        { value: 5, text: this.$t("monthNames.may") },
        { value: 6, text: this.$t("monthNames.june") },
        { value: 7, text: this.$t("monthNames.july") },
        { value: 8, text: this.$t("monthNames.aug") },
        { value: 9, text: this.$t("monthNames.sep") },
        { value: 10, text: this.$t("monthNames.oct") },
        { value: 11, text: this.$t("monthNames.nov") },
        { value: 12, text: this.$t("monthNames.dec") },
      ];
    },
  },

  watch: {
    showModal(value) {
      if (value && this.orderId) {
        this.fetchDeliveryNote();
        this.$refs["post-delivery-note-modal"].show();
      }
    },
  },
};
</script>