 <template>
  <div class="container-fluid">
    <b-modal
      ref="post-used-material-modal"
      size="lg"
      :title="usedMaterialId ? $t('materialMsgs.editMaterial') : $t('materialMsgs.addMaterial')"
      scrollable
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12">
                  <div
                    class="
                      custom-control
                      custom-switch
                      custom-switch-color
                      custom-control-inline
                    "
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input bg-success"
                      id="customSwitch02"
                      v-model="enableCustomUnitPrice"
                      @click="unitPrice = null"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitch02"
                      >{{ $t("materialMsgs.enableCustomUnitPrice") }}</label
                    >
                  </div>
                </div>
                <div class="form-group col-md-12" v-if="order">
                  <label for="order">{{ $t('orderMsgs.order') }}</label>
                  <input type="text" class="form-control" id="order" :value="order.order_number" disabled />
                </div>
                <template v-if="!usedMaterialId">
                  <!-- Section for metal sheet -->
                  <strong class="ml-3">{{ $t('materialTypeChoices.metal_sheet') }}</strong>
                  <div
                    class="row mx-0 col-md-12"
                    v-for="(metalSheet, index) in metalSheets"
                    :key="'metalSheet' + index"
                  >
                    <div :class="`form-group col-md-${enableCustomUnitPrice ? '5' : '6'} px-1`">
                      <label for="material">{{ $t('materialMsgs.material') }}</label>

                      <v-select
                        id="material"
                        class="form-control v-select-custom"
                        label="material_name"
                        v-model="metalSheets[index].material"
                        :reduce="(material) => material.id"
                        :placeholder="$t('orderMsgs.materialPlaceholder')"
                        :options="allMetalSheets"
                        :loading="areMaterialsLoading"
                        @option:selected="onMetalSheetSelect"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :required="!metalSheet.material && metalSheet.quantity"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.material_name }}
                          <i
                            >{{
                              option.type === METAL_SHEET
                                ? `(${$n(option.material_thickness, 'number', 'de-DE')}mm)`
                                : ''
                            }}
                          </i>
                        </template>
                        />
                        <template slot="selected-option" slot-scope="option">
                          <span>{{ option.material_name }}</span>
                          <i
                            >{{
                              option.type === METAL_SHEET
                                ? `(${$n(option.material_thickness, 'number', 'de-DE')}mm)`
                                : ''
                            }}
                          </i>
                        </template>
                      </v-select>
                    </div>

                    <div :class="`form-group col-md-3 px-1`" v-if="enableCustomUnitPrice">
                      <label for="metalSheetPrice">{{ $t('materialMsgs.unitPrice') }}</label>
                      <currency-input
                        class="form-control"
                        id="metalSheetPrice"
                        :placeholder="$t('materialMsgs.unitPrice')"
                        v-model="metalSheets[index].unit_price"
                        :precision="2"
                        :allow-negative="false"
                      />
                    </div>

                    <div :class="`form-group col-md-${enableCustomUnitPrice ? '3' : '5'} px-1`">
                      <label for="quantity">{{ $t('orderMsgs.quantity') }}</label>
                      <currency-input
                        class="form-control"
                        id="quantity"
                        :placeholder="$t('orderMsgs.quantity')"
                        v-model="metalSheets[index].quantity"
                        :precision="2"
                        :currency="null"
                        :allow-negative="false"
                        :required="metalSheet.material"
                      />
                    </div>

                    <div class="form-group col-md-1 px-0 align-self-end pb-2">
                      <b-button class="mr-1 btn-xs" @click="addMetalSheet(index)" variant="outline-primary">
                        <i class="fas fa-sm fa-plus" />
                      </b-button>
                      <b-button
                        class="btn-xs"
                        @click.prevent="removeMetalSheet(index)"
                        variant="outline-danger"
                        v-if="metalSheets.length > 1"
                      >
                        <i class="fas fa-sm fa-minus" />
                      </b-button>
                    </div>
                  </div>

                  <!-- Section for Screw -->
                  <strong class="ml-3">{{ $t('materialTypeChoices.screw') }}</strong>
                  <div class="row mx-0 col-md-12" v-for="(screw, index) in screws" :key="'screw' + index">
                    <div :class="`form-group col-md-${enableCustomUnitPrice ? '5' : '6'} px-1`">
                      <label for="material">{{ $t('materialMsgs.material') }}</label>

                      <v-select
                        id="material"
                        class="form-control v-select-custom"
                        label="material_name"
                        v-model="screws[index].material"
                        :reduce="(material) => material.id"
                        :placeholder="$t('orderMsgs.materialPlaceholder')"
                        :options="allScrews"
                        :loading="areMaterialsLoading"
                        @option:selected="onScrewSelect"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :required="!screw.material && screw.quantity"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.material_name }}
                          <i
                            >{{
                              option.type === METAL_SHEET
                                ? `(${$n(option.material_thickness, 'number', 'de-DE')}mm)`
                                : ''
                            }}
                          </i>
                        </template>
                        />
                        <template slot="selected-option" slot-scope="option">
                          <span>{{ option.material_name }}</span>
                          <i
                            >{{
                              option.type === METAL_SHEET
                                ? `(${$n(option.material_thickness, 'number', 'de-DE')}mm)`
                                : ''
                            }}
                          </i>
                        </template>
                      </v-select>
                    </div>

                    <div class="form-group col-md-3 px-1" v-if="enableCustomUnitPrice">
                      <label for="screwPrice">{{ $t('materialMsgs.unitPrice') }}</label>
                      <currency-input
                        class="form-control"
                        id="screwPrice"
                        :placeholder="$t('materialMsgs.unitPrice')"
                        v-model="screws[index].unit_price"
                        :precision="2"
                        :allow-negative="false"
                      />
                    </div>

                    <div :class="`form-group col-md-${enableCustomUnitPrice ? '3' : '5'} px-1`">
                      <label for="quantity">{{ $t('orderMsgs.quantity') }}</label>
                      <currency-input
                        class="form-control"
                        id="quantity"
                        :placeholder="$t('orderMsgs.quantity')"
                        v-model="screws[index].quantity"
                        :precision="2"
                        :currency="null"
                        :allow-negative="false"
                        :required="screw.material"
                      />
                    </div>

                    <div class="form-group col-md-1 px-0 align-self-end pb-2">
                      <b-button class="mr-1 btn-xs" @click="addScrew(index)" variant="outline-primary">
                        <i class="fas fa-sm fa-plus" />
                      </b-button>
                      <b-button
                        class="btn-xs"
                        @click.prevent="removeScrew(index)"
                        variant="outline-danger"
                        v-if="screws.length > 1"
                      >
                        <i class="fas fa-sm fa-minus" />
                      </b-button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="form-group col-md-12">
                    <label for="material">{{ $t('materialMsgs.material') }}</label>

                    <v-select
                      id="material"
                      class="form-control v-select-custom"
                      label="material_name"
                      v-model="materialId"
                      :reduce="(material) => material.id"
                      :placeholder="$t('orderMsgs.materialPlaceholder')"
                      :options="allMaterials"
                      :loading="areMaterialsLoading"
                      @option:selected="row => unitPrice = row.material_price"
                    >
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!materialId" v-bind="attributes" v-on="events" />
                      </template>
                      <template slot="option" slot-scope="option">
                        <i>{{ $t(`materialTypeChoices.${option.type}`) }}</i> - {{ option.material_name }}
                        <i
                          >{{
                            option.type === METAL_SHEET ? `(${$n(option.material_thickness, 'number', 'de-DE')}mm)` : ''
                          }}
                        </i>
                      </template>
                      />
                      <template slot="selected-option" slot-scope="option">
                        <i>{{ $t(`materialTypeChoices.${option.type}`) }}</i
                        ><span class="ml-1">- {{ option.material_name }}</span>
                        <i
                          >{{
                            option.type === METAL_SHEET ? `(${$n(option.material_thickness, 'number', 'de-DE')}mm)` : ''
                          }}
                        </i>
                      </template>
                    </v-select>
                  </div>

                  <div class="form-group col-md-12" v-if="enableCustomUnitPrice">
                    <label for="unitPrice">{{ $t('materialMsgs.unitPrice') }}</label>
                    <currency-input
                      class="form-control"
                      id="unitPrice"
                      :placeholder="$t('materialMsgs.unitPrice')"
                      v-model="unitPrice"
                      :precision="2"
                      :allow-negative="false"
                      required
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="quantity">{{ $t('orderMsgs.quantity') }}</label>
                    <currency-input
                      class="form-control"
                      id="quantity"
                      :placeholder="$t('orderMsgs.quantity')"
                      v-model="quantity"
                      :precision="2"
                      :currency="null"
                      :allow-negative="false"
                      required
                    />
                  </div>
                </template>
              </div>

              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isLoading || areMaterialsLoading"
                  style="min-width: 100px"
                >
                  <i v-if="isLoading || areMaterialsLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{ usedMaterialId ? $t('update') : $t('add') }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import vSelect from 'vue-select';
import { MATERIAL_TYPES } from '../../../common/constants';
import { groupBy } from 'lodash';

export default {
  name: 'PostUsedMaterialModal',

  props: {
    showModal: Boolean,
    orderId: Number,
    order: Object,
    usedMaterialId: Number,
  },

  data() {
    return {
      materialId: null,
      quantity: null,
      unitPrice: null,
      metalSheets: [],
      screws: [],

      allMaterials: [],
      allMetalSheets: [],
      allScrews: [],

      isLoading: false,
      areMaterialsLoading: false,
      enableCustomUnitPrice: false,
      ...MATERIAL_TYPES,
    };
  },

  methods: {
    ...mapActions(['getUsedMaterial', 'createUsedMaterial', 'updateUsedMaterial', 'getAllMaterials']),
    groupBy,

    resetData() {
      this.screws = this.metalSheets = this.allScrews = this.allMetalSheets = this.allMaterials = [];
      this.materialId = this.quantity = null;
      this.isLoading = this.enableCustomUnitPrice = false;
    },

    hideModal() {
      this.resetData();
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs['post-used-material-modal'].hide();
      this.hideModal();
    },

    hideWithUpdateModal() {
      this.resetData();
      this.$refs['post-used-material-modal'].hide();
      this.$emit('closeUpdate');
    },

    addMetalSheet(index) {
      this.metalSheets.splice(index + 1, 0, { order: this.orderId, material: null, quantity: null });
    },

    addScrew(index) {
      this.screws.splice(index + 1, 0, { order: this.orderId, material: null, quantity: null });
    },

    removeMetalSheet(index) {
      this.metalSheets.splice(index, 1);
    },

    removeScrew(index) {
      this.screws.splice(index, 1);
    },

    onMetalSheetSelect(row) {
      this.metalSheets = this.metalSheets.map((sheet) => {
        if (sheet.material === row.id){
          sheet.unitPrice = row.material_price
        }
        return sheet
      })
    },

    onScrewSelect(row) {
      this.screws = this.screws.map((screw) => {
        if (screw.material === row.id){
          screw.unitPrice = row.material_price
        }
        return screw
      })
    },

    async fetchUsedMaterial() {
      try {
        const usedMat = (await this.getUsedMaterial(this.usedMaterialId)).data;
        this.materialId = usedMat.material.id;
        this.quantity = usedMat.quantity;
        this.unitPrice = usedMat.unit_price
        if (this.unitPrice) {
          this.enableCustomUnitPrice = true
        }
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        this.hideModalManual();
      }
    },

    async fetchMaterials(params = {}) {
      this.areMaterialsLoading = true;
      const response = await this.getAllMaterials({
        ...params,
      });
      this.allMaterials = response.data;
      const result = groupBy(this.allMaterials, (material) => material.type);
      this.allMetalSheets = result.metal_sheet;
      this.allScrews = result.screw;
      this.areMaterialsLoading = false;
    },

    async onSubmit() {
      // TODO: Add validations.

      this.isLoading = true;

      try {
        const data = {
          order: this.orderId,
          material: this.materialId,
          quantity: this.quantity,
          unit_price: this.unitPrice
        };
        if (this.usedMaterialId) {
          await this.updateUsedMaterial({
            id: this.usedMaterialId,
            data,
          });
          this.makeToast('success', this.$t('materialMsgs.materialUpdated'));
          this.hideWithUpdateModal();
        } else {
          const filteredSheets = this.metalSheets.filter((item) => item.material && item.quantity);
          const filteredScrews = this.screws.filter((item) => item.material && item.quantity);

          if (filteredScrews.length || filteredSheets.length) {
            const materials = filteredScrews.concat(filteredSheets);

            await this.createUsedMaterial(materials);
            this.makeToast('success', this.$t('materialMsgs.materialAdded'));
            this.hideWithUpdateModal();
          } else {
            this.makeToast('danger', this.$t('materialMsgs.noFieldMsg'));
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isLoading = false;
    },

    async fetchRelatedData() {
      this.isLoading = true;

      await Promise.all([this.usedMaterialId ? this.fetchUsedMaterial() : undefined, this.fetchMaterials()]);

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  components: { vSelect },

  async mounted() {},

  computed: {},

  watch: {
    showModal(value) {
      if (value && this.orderId) {
        this.fetchRelatedData();
        this.$refs['post-used-material-modal'].show();
        if (!this.usedMaterialId) {
          this.metalSheets = [{ order: this.orderId, material: null, quantity: null, unitPrice: null }];
          this.screws = [{ order: this.orderId, material: null, quantity: null, unitPrice: null }];
        }
      }
    },
  },
};
</script>
