<template>
  <div class="container-fluid">
    <b-modal
      ref="post-invoice-modal"
      size="lg"
      :title="$t('invoiceMsgs.manageInvoice')"
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12" v-if="order">
                  <label for="order">{{ $t("orderMsgs.order") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="order"
                    :value="order.order_number"
                    disabled
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="invoiceNum">{{
                    $t("invoiceMsgs.invoiceNum")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="invoiceNum"
                    :placeholder="$t('invoiceMsgs.invoiceNumPlaceholder')"
                    v-model="invoiceNum"
                    required
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="date">{{ $t("date") }}</label>

                  <b-form-datepicker
                    v-model="date"
                    :locale="getCurrLanguage"
                    required
                  ></b-form-datepicker>
                </div>
              </div>

              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isLoading"
                  style="min-width: 100px"
                >
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{ invoiceId ? $t("update") : $t("add") }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { get, head } from "lodash";

export default {
  name: "PostInvoiceModal",

  props: {
    showModal: Boolean,
    orderId: Number,
    order: Object,
  },

  data() {
    return {
      invoiceNum: null,
      date: moment().format("YYYY-MM-DD"),
      invoice: null,
      invoiceId: null,

      isLoading: false,
    };
  },

  methods: {
    ...mapActions([
      "getInvoices",
      "createInvoice",
      "updateInvoice",
      "getOrder",
    ]),

    resetData() {
      this.date = moment().format("YYYY-MM-DD");
      this.invoice = this.invoiceId = this.invoiceNum = null;
      this.isLoading = false;
    },

    hideModal() {
      this.$emit("close");
      this.resetData();
    },

    hideModalManual() {
      this.$refs["post-invoice-modal"].hide();
      this.hideModal();
    },

    hideWithUpdateModal() {
      this.$refs["post-invoice-modal"].hide();
      this.$emit("closeUpdate");
      this.resetData();
    },

    async fetchInvoice() {
      this.isLoading = true;

      try {
        const invoices = (await this.getInvoices({ order: this.orderId })).data;

        if (get(invoices, "length")) {
          this.invoice = head(invoices);
          this.invoiceId = this.invoice.id;
          this.invoiceNum = this.invoice.invoice_number;
          this.date = this.invoice.invoice_date;
        }
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        this.hideModalManual();
      }

      this.isLoading = false;
    },

    async onSubmit() {
      // TODO: Add validations.

      this.isLoading = true;

      try {
        const invoiceData = {
          order: this.orderId,
          invoice_date: this.date,
          invoice_number: this.invoiceNum,
        };
        if (this.invoiceId) {
          await this.updateInvoice({
            id: this.invoiceId,
            data: invoiceData,
          });
          this.makeToast("success", this.$t("invoiceMsgs.invoiceUpdated"));
        } else {
          await this.createInvoice(invoiceData);
          this.makeToast("success", this.$t("invoiceMsgs.invoiceAdded"));
        }
        this.isLoading = false;
        // this.hideWithUpdateModal();
        this.hideModalManual();
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {
    ...mapGetters(["getCurrLanguage"]),
  },

  watch: {
    showModal(value) {
      if (value && this.orderId) {
        this.fetchInvoice();
        this.$refs["post-invoice-modal"].show();
      }
    },
  },
};
</script>