<template>
  <div class="d-none">
    <div class="row" id="printableComp">
      <div class="col-12 print-font" v-if="order && deliveryNote">
        <!-- Print 2 pages for the same delivery note. First one is the
        original one while the second one is a copy.  -->
        <div
          class="col-12"
          v-for="pageNum in 2"
          :key="pageNum"
          style="page-break-after: always; margin-top: 250px !important"
        >
          <b-row class="mt-5">
            <b-col cols="12" md="12" sm="12">
              <h5>{{ order.customer.name }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12" sm="12">
              <h5>{{ order.customer.street }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7" md="7" sm="7">
              <h5>{{ order.customer.post_code }} {{ order.customer.town }}</h5>
            </b-col>
            <b-col cols="5" md="5" sm="5"> Tel +49(0)621/5879019‐6 </b-col>
          </b-row>

          <!-- <b-row>
            <b-col cols="7" md="7" sm="7" />
            <b-col cols="5" md="5" sm="5"> Tel +49(0)621/5879019‐6 </b-col>
          </b-row> -->
          <b-row>
            <b-col cols="7" md="7" sm="7" />
            <b-col cols="5" md="5" sm="5"> Fax +49(0)621/67181282 </b-col>
          </b-row>
          <b-row>
            <b-col cols="7" md="7" sm="7" />
            <b-col cols="5" md="5" sm="5">
              <a href="mailto:info@planwerk24.com">info@planwerk24.com</a>
            </b-col>
          </b-row>

          <div class="d-flex justify-content-center">
            <strong v-if="pageNum === 2" class="copy-font">-{{ $t('generalMsgs.copy') }}-</strong>
          </div>

          <b-row class="mt-5">
            <b-col cols="3" md="3" sm="3">
              <h4>
                <strong>{{ $t('deliveryNoteMsgs.noteNumPrint') }}:</strong>
              </h4>
            </b-col>
            <b-col cols="4" md="4" sm="4">
              <h4>
                <strong>{{ deliveryNote.delivery_note_number }}</strong>
              </h4>
            </b-col>

            <b-col cols="2" md="2" sm="2">
              {{ $t('date') }}
            </b-col>
            <b-col cols="2" md="2" sm="2" class="text-right">
              {{ formatDate(deliveryNote.date) }}
            </b-col>
          </b-row>
          <b-row style="margin-top: -0.5rem !important">
            <b-col cols="7" md="7" sm="7" />
            <b-col cols="2" md="2" sm="2">
              {{ $t('deliveryNoteMsgs.workMonth') }}
            </b-col>
            <b-col cols="2" md="2" sm="2" class="text-right text-break">
             {{allMonthsOptions[deliveryNote.work_month]}}
            </b-col>

          </b-row>
  
          <b-row class="mt-1">
            <b-col cols="7" md="7" sm="7" />
            <b-col cols="2" md="2" sm="2">
              {{ $t('customerMsgs.custNumDelPrint') }}
            </b-col>
            <b-col cols="2" md="2" sm="2" class="text-right text-break">
              {{ order.customer.customer_number }}
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col cols="3" md="3" sm="3">
              <h5>{{ $t('deliveryNoteMsgs.orderTitlePrint') }}:</h5>
            </b-col>
            <b-col cols="9" md="9" sm="9">
              <h5 class="text-break">{{ order.order_number }}</h5>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3" md="3" sm="3"> {{ $t('orderMsgs.constructionProj') }}: </b-col>
            <b-col cols="9" md="9" sm="9" class="text-break">
              {{ order.construction_project }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3" md="3" sm="3"> {{ $t('costCenterMsgs.costCenter') }}: </b-col>
            <b-col cols="9" md="9" sm="9" class="text-break">
              {{ order.cost_center.center_number }}{{ order.cost_center.name ? ` - ${order.cost_center.name}` : '' }}
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col cols="12" md="12" sm="12"> {{ $t('deliveryNoteMsgs.notePrintDeliveryMsg') }}: </b-col>
          </b-row>

          <b-table :items="tableData" :fields="tableColumns" class="mt-3">
            <template #cell(amountME)> 1,00 {{ $t('deliveryNoteMsgs.printTablePiece') }} </template>
            <template #cell(description)>
              {{ $t('deliveryNoteMsgs.printSheetPrepMsgs') }} <br />
              {{ $t('orderMsgs.sheetNums') }}: <span class="text-break">{{ order.sheet_numbers }}</span>
            </template>
          </b-table>

          <b-row style="margin-top: 5rem !important">
            <span class="col-7">{{ $t('deliveryNoteMsgs.notePrintGoodsReceivedMsg') }}:</span>
          </b-row>

          <b-row style="margin-top: 5rem !important">
            <span class="col-7" style="border-top: 1px solid"
              >{{ $t('date') }} / {{ $t('generalMsgs.signature') }}</span
            >
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import printJS from 'print-js';
import { mapActions } from 'vuex';
import { formatDate, formatDateTime } from '../../../common/utils';
import { get, head, cloneDeep } from 'lodash';

export default {
  name: 'order-sheet-print',

  props: {
    orderId: { type: Number, required: true },
    orderData: Object,
    delNoteData: Object,
  },
  data() {
    return {
      order: cloneDeep(this.orderData),
      deliveryNote: cloneDeep(this.delNoteData),

      isOrderLoading: true,
      isDelNoteLoading: true,
      isPrinting: true,

      tableData: [
        {
          num: 1,
          amountME: '1,00 Stk',
          description: 'Preparation of various metal sheets.',
        },
      ],
    };
  },

  methods: {
    ...mapActions(['getOrder', 'getDeliveryNotes']),
    formatDate,
    formatDateTime,

    async print() {
      if (this.isOrderLoading || this.isDelNoteLoading) {
        throw Error('Order data not loaded.');
      }

      this.isPrinting = true;

      printJS({
        printable: 'printableComp',
        type: 'html',
        css: 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css',
        scanStyles: false,
        documentTitle: this.$t('orderMsgs.deliveryNote'),
        style: `
          .print-font span, .print-font p, .print-font strong, .print-font th, .print-font td, .print-font div {
            font-size: 20px !important;
          }
          .print-font h4, .print-font h4 strong {
            font-size: 28px !important;
          }
          .print-font h5, .print-font h5 strong {
            font-size: 24px !important;
          }
          strong.copy-font {
            text-align: center !important;
            font-size: 30px !important;
          }
        `,

        onPrintDialogClose: () => {
          this.isPrinting = false;
        },
        onError: () => {
          this.isPrinting = false;
        },
      });

      await this.$nextTick();
      this.isPrinting = false;
    },

    async fetchReqData() {
      await Promise.all([this.fetchOrder(), this.fetchDeliveryNote()]);
      this.$emit('dataLoaded');
    },

    async fetchOrder() {
      this.isOrderLoading = true;

      const response = await this.getOrder(this.orderId);
      this.order = response.data;

      this.isOrderLoading = false;
    },

    async fetchDeliveryNote() {
      this.isDelNoteLoading = true;
      this.deliveryNote = null;

      try {
        const note = (await this.getDeliveryNotes({ order: this.orderId })).data;

        if (get(note, 'length')) {
          this.deliveryNote = head(note);
          this.$emit('noteFound');
        }
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isDelNoteLoading = false;
    },
  },

  mounted() {
    if (this.orderData && this.delNoteData) {
      this.order = cloneDeep(this.orderData);
      this.deliveryNote = cloneDeep(this.delNoteData);
      this.isOrderLoading = false;
      this.isDelNoteLoading = false;
      this.$emit('dataLoaded');
    } else {
      this.fetchReqData();
    }
  },

  watch: {
    orderData: {
      deep: true,
      handler() {
        this.order = cloneDeep(this.orderData);
      },
    },

    delNoteData: {
      deep: true,
      handler() {
        this.deliveryNote = cloneDeep(this.delNoteData);
      },
    },
  },

  computed: {
    tableColumns() {
      return [
        { key: 'num', label: this.$t('deliveryNoteMsgs.printPosition'), thStyle: { width: '120px' } },
        { key: 'amountME', label: this.$t('deliveryNoteMsgs.printAmountME'), thStyle: { width: '180px' } },
        { key: 'description', label: this.$t('deliveryNoteMsgs.printTableDesc') },
      ];
    },
    allMonthsOptions() {
      return {
          1: this.$t("monthNames.jan"),
          2: this.$t("monthNames.feb"),
          3: this.$t("monthNames.march"),
          4: this.$t("monthNames.april"),
          5: this.$t("monthNames.may"),
          6: this.$t("monthNames.june"),
          7: this.$t("monthNames.july"),
          8: this.$t("monthNames.aug"),
          9: this.$t("monthNames.sep"),
          10: this.$t("monthNames.oct"),
          11: this.$t("monthNames.nov"),
          12:this.$t("monthNames.dec")
        }
      },
  },
};
</script>
