<template>
  <div class="d-none">
    <div class="row" id="printableSheet">
      <div class="col-12 mt-5" v-if="order">
        <div class="col-12 mt-5 order-sheet-font" style="page-break-after: always; margin-top: 400px !important">
          <b-row class="mt-5">
            <b-col cols="3" md="3" sm="3">
              <h2>
                <strong>{{ $t('orderMsgs.orderNumShort') }}:</strong>
              </h2>
            </b-col>
            <b-col cols="5" md="5" sm="5">
              <h2 class="ml-4">
                <strong>{{ order.order_number }}</strong>
              </h2>
            </b-col>

            <b-col cols="2" md="2" sm="2" class="d-flex justify-content-end">
              <h4>
                <strong>{{ $t('date') }}:</strong>
              </h4>
            </b-col>
            <b-col cols="2" md="2" sm="2">
              <h4>
                <strong>{{ formatDate(order.received_date) }} </strong>
              </h4>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="10" md="10" sm="10">
              <b-row style="margin-top: 3rem !important">
                <b-col cols="4" md="4" sm="4">
                  <h4>
                    <strong>{{ $t('customerMsgs.customer') }}:</strong>
                  </h4>
                </b-col>
                <b-col cols="8" md="8" sm="8">
                  <h4 class="text-break">{{ order.customer.name }}</h4>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="4" md="4" sm="4">
                  <h4>
                    <strong>{{ $t('orderMsgs.constructionProj') }}:</strong>
                  </h4>
                </b-col>
                <b-col cols="8" md="8" sm="8">
                  <h4 class="text-break">{{ order.construction_project }}</h4>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="4" md="4" sm="4">
                  <h4>
                    <strong>{{ $t('costCenterMsgs.costCenter') }}:</strong>
                  </h4>
                </b-col>
                <b-col cols="8" md="8" sm="8">
                  <h4 class="text-break">
                    {{ order.cost_center.center_number
                    }}{{ order.cost_center.name ? ` - ${order.cost_center.name}` : '' }}
                  </h4>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="4" md="4" sm="4">
                  <h4>
                    <strong>{{ $t('orderMsgs.sheetNums') }}:</strong>
                  </h4>
                </b-col>
                <b-col cols="8" md="8" sm="8">
                  <h4 class="text-break">{{ order.sheet_numbers }}</h4>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="2" md="2" sm="2">
              <b-row style="margin-top: 2rem !important">
                <qrcode-vue :value="order.order_number" :size="150" level="H"></qrcode-vue>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <div class="cost-sheet-font" >
          <b-row class="mt-3 text-center">
            <b-col cols="12" md="12" sm="12">
              <h1 style="font-size: xxx-large">
                <strong>{{ $t('costSheetMsgs.title') }}</strong>
              </h1>
            </b-col>
          </b-row>

          <b-row style="margin-top: 3rem !important">
            <b-col cols="6" md="6" sm="6">
              <b-table-simple fixed responsive small table-class="bordered-table small-height">
                <b-tbody>
                  <b-tr>
                    <b-td class="w-5-12">
                      <strong>{{ $t('costSheetMsgs.customer') }}:</strong>
                    </b-td>
                    <b-td class="w-7-12">
                      <span>{{ order.customer.name }}</span>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col cols="6" md="6" sm="6">
              <b-table-simple fixed responsive small table-class="bordered-table small-height">
                <b-tbody>
                  <b-tr>
                    <b-td class="w-5-12">
                      <strong>{{ $t('costSheetMsgs.orderNumber') }}:</strong>
                    </b-td>
                    <b-td class="w-7-12">
                      <span>{{ order.order_number }}</span>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" md="6" sm="6">
              <b-table-simple fixed responsive small table-class="bordered-table small-height">
                <b-tbody>
                  <b-tr>
                    <b-td class="w-5-12">
                      <strong>{{ $t('costSheetMsgs.constructionProject') }}:</strong>
                    </b-td>
                    <b-td class="w-7-12">
                      <span>{{ order.cost_center.center_number }}</span>
                      <span v-if="order.cost_center.name">- {{ order.cost_center.name }}</span>
                      <span v-if="order.construction_project" class="ml-1">({{ order.construction_project }})</span>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col cols="6" md="6" sm="6">
              <b-table-simple fixed responsive small table-class="bordered-table small-height">
                <b-tbody>
                  <b-tr>
                    <b-td class="w-5-12">
                      <strong>{{ $t('costSheetMsgs.date') }}:</strong>
                    </b-td>
                    <b-td class="w-7-12">
                      <span>{{ formatDate(order.received_date) }}</span>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" md="6" sm="6">
              <b-table-simple fixed responsive small table-class="bordered-table small-height">
                <b-tbody>
                  <b-tr>
                    <b-td class="w-5-12">
                      <strong>{{ $t('costSheetMsgs.deliveryNoteNumber') }}:</strong>
                    </b-td>
                    <b-td class="w-7-12"></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col cols="6" md="6" sm="6">
              <b-table-simple fixed responsive small table-class="bordered-table small-height">
                <b-tbody>
                  <b-tr>
                    <b-td class="w-5-12">
                      <strong>{{ $t('costSheetMsgs.sheetNumbers') }}:</strong>
                    </b-td>
                    <b-td class="w-7-12" />
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="12" md="12" sm="12">
              <h3 style="text-decoration: underline !important">
                <strong>{{ $t('materialMsgs.material') }}:</strong>
              </h3>
              <b-table-simple fixed responsive small table-class="bordered-table small-height">
                <b-thead>
                  <b-tr>
                    <b-th class="w-5-12">
                      {{ $t('costSheetMsgs.sheetType') }}
                    </b-th>
                    <b-th class="w-3-12">
                      {{ $t('costSheetMsgs.sheetThickness') }}
                    </b-th>
                    <b-th class="w-3-12">
                      {{ $t('costSheetMsgs.sheetNumber') }}
                    </b-th>
                    <b-th class="w-1-12">
                      {{ $t('costSheetMsgs.sheetUnit') }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="row in 4" :key="row">
                    <b-td class="w-5-12"></b-td>
                    <b-td class="w-3-12"></b-td>
                    <b-td class="w-3-12"></b-td>
                    <b-td class="w-1-12"><strong>m2</strong></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <h3 style="text-decoration: underline !important" class="ml-3">
              <strong>{{ $t('costSheetMsgs.itemsTitle') }}:</strong>
            </h3>
            <div class="p-0 col-12">
              <b-table-simple
                fixed
                responsive
                small
                class="col-6 w-50 float-left"
                table-class="bordered-table small-height"
              >
                <b-tbody>
                  <template v-for="(consumable, index) in consumablesTable1">
                    <b-tr :key="index">
                      <b-th class="w-1-12">{{ consumable.num }}</b-th>
                      <b-td class="font-weight-bold w-4-12">{{ consumable.label }}</b-td>
                      <b-td class="font-weight-bold w-2-12">{{ consumable.size }}</b-td>
                      <b-td class="font-weight-bold w-2-12">{{ consumable.type }}</b-td>
                      <b-td class="w-2-12"></b-td>
                      <b-td class="font-weight-bold w-1-12">{{ consumable.unit }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>

              <b-table-simple fixed responsive small class="col-6 w-50" table-class="bordered-table small-height">
                <b-tbody>
                  <template v-for="(consumable, index) in consumablesTable2">
                    <b-tr :key="index">
                      <b-th class="w-1-12">{{ consumable.num }}</b-th>
                      <b-td class="font-weight-bold w-4-12">{{ consumable.label }}</b-td>
                      <b-td class="font-weight-bold w-2-12">{{ consumable.size }}</b-td>
                      <b-td class="font-weight-bold w-2-12">{{ consumable.type }}</b-td>
                      <b-td class="w-2-12"></b-td>
                      <b-td class="font-weight-bold w-1-12">{{ consumable.unit }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-row>

          <b-row class="mt-4">
            <h3 style="text-decoration: underline !important" class="ml-3">
              <strong>{{ $t('costSheetMsgs.hours') }}:</strong>
            </h3>
            <b-col cols="12" sm="12" md="12">
              <b-table-simple small table-class="bordered-table small-height small-width w-100 name-table">
                <b-tbody>
                  <b-tr>
                    <th>{{ $t('name') }}</th>
                    <th v-for="col in 5" :key="col">{{ col }}.</th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col cols="12" sm="12" md="12">
              <b-table-simple fixed responsive small class="mt-2" table-class="bordered-table">
                <b-thead class="text-center">
                  <b-tr>
                    <template v-for="outer in 4">
                      <b-th :key="outer"
                        ><span class="invisible">{{ outer }}</span></b-th
                      >
                      <b-th v-for="inner in 5" :key="`${outer} - ${inner}`">{{ inner }}</b-th>
                    </template>
                  </b-tr>
                </b-thead>
                <b-tbody class="text-center">
                  <template v-for="row in 8">
                    <b-tr :key="row">
                      <template v-for="col in [row, row + 8, row + 16, row + 24]">
                        <template v-if="col !== 32">
                          <b-th scope="row" :key="col">{{ 10 > col ? `0${col}` : col }}.</b-th>
                          <b-td v-for="emptyCell in 5" :key="`${emptyCell} - ${col}`"
                            ><span class="invisible">{{ emptyCell }}</span></b-td
                          >
                        </template>
                      </template>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import printJS from 'print-js';
import QrcodeVue from 'qrcode.vue';
import { mapActions } from 'vuex';
import { formatDate, formatDateTime } from '../../../common/utils';
import { get, head, cloneDeep } from 'lodash';

export default {
  name: 'order-sheet-print',

  props: {
    showTab: Boolean,
    orderId: { type: Number, required: true },
    orderData: Object,
  },
  data() {
    return {
      order: cloneDeep(this.orderData),

      deliveryNote: undefined,
      invoice: undefined,

      isDataLoading: true,
      isDelNoteLoading: true,
      isInvoiceLoading: true,
      isCostLoading: true,
      isOrderSheetPrinting: true,
    };
  },

  components: {
    QrcodeVue,
  },

  methods: {
    ...mapActions(['getOrder', 'getDeliveryNotes', 'getInvoices', 'getOrdersCosts']),
    formatDate,
    formatDateTime,

    print() {
      if (this.isDataLoading) {
        throw Error('Order data not loaded.');
      }

      let style = '';
      for (let index = 1; index < 13; index++) {
        style =
          style +
          `.w-${index}-12 {
              width: calc(100% * ${index} / 12) !important;
            }\n`;
      }

      this.isOrderSheetPrinting = true;
      printJS({
        printable: 'printableSheet',
        type: 'html',
        css: 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css',
        scanStyles: false,
        documentTitle: this.$t('orderMsgs.orderSheet'),
        targetStyle: 'border',
        style:
          style +
          `
            table.bordered-table td, th {
              border: 2px solid black !important;              
            }
            table.small-height td, th {
              line-height: 1.35rem !important;
              /* width: 2.53rem !important; */
            }
            table.small-width td, th {
              width: 2.53rem !important;
            }
            .name-table th {
              border-top: none !important;
              width: 16.6666666667% !important;
            }
            .order-sheet-font strong, .order-sheet-font h4, .order-sheet-font span {
              font-size: 28px !important;
            }
            .order-sheet-font h2 strong {
              font-size: 38px !important;
            }
            .cost-sheet-font strong,
            .cost-sheet-font h4,
            .cost-sheet-font span,
            .cost-sheet-font i,
            .cost-sheet-font td,
            .cost-sheet-font th {
              font-size: 20px !important;
            }
            .cost-sheet-font h1 strong {
              font-size: 50px !important;
            }
            .cost-sheet-font h3 strong {
              font-size: 36px !important;
            }
          `,

        onPrintDialogClose: () => {
          this.isOrderSheetPrinting = false;
        },
        onError: () => {
          this.isOrderSheetPrinting = false;
        },
      });
    },

    async fetchOrder() {
      this.isDataLoading = true;

      const response = await this.getOrder(this.orderId);
      this.order = response.data;

      this.isDataLoading = false;
      this.$emit('dataLoaded');
    },

    async fetchOrderDetails() {
      this.fetchDeliveryNote();
      this.fetchInvoice();
      this.fetchOrderCost();
      this.order['rawRevTotal'] = this.order.material_revenue + this.order.labor_revenue;
      this.order['totalRevenue'] = (this.order.material_revenue + this.order.labor_revenue) * this.order.revenue_factor;
    },

    async fetchDeliveryNote() {
      this.isDelNoteLoading = true;
      this.deliveryNote = null;

      if (this.order) {
        try {
          const note = (await this.getDeliveryNotes({ order: this.orderId })).data;

          if (get(note, 'length')) {
            this.deliveryNote = head(note);
          }
        } catch (err) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
      }

      this.isDelNoteLoading = false;
    },

    async fetchInvoice() {
      this.isInvoiceLoading = true;
      this.invoice = null;

      if (this.order) {
        try {
          const invoices = (await this.getInvoices({ order: this.order.id })).data;

          if (get(invoices, 'length')) {
            this.invoice = head(invoices);
          }
        } catch (err) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
      }

      this.isInvoiceLoading = false;
    },

    async fetchOrderCost() {
      this.isCostLoading = true;

      const costs = (await this.getOrdersCosts({ orderIds: [this.order.id] })).data;
      this.order['cost'] = head(costs) || {};

      this.isCostLoading = false;
    },
  },

  mounted() {
    if (this.orderData) {
      this.order = cloneDeep(this.orderData);
      this.isDataLoading = false;
      this.$emit('dataLoaded');
    } else {
      this.fetchOrder();
    }
  },

  watch: {
    orderData: {
      deep: true,
      handler() {
        this.order = cloneDeep(this.orderData);
      },
    },
  },

  computed: {
    consumablesTable1() {
      return [
        { num: 43, label: 'Nieten', size: '3,2x8', type: 'Alu', unit: 'Stk.' },
        { num: 32, label: 'Nieten', size: '3,2x8', type: 'Verz.', unit: 'Stk.' },
        { num: 30, label: 'Nieten', size: '3,2x8', type: 'VA', unit: 'Stk.' },
        { num: 44, label: 'Nieten', size: '4,8x10', type: 'Alu', unit: 'Stk.' },
        { num: 33, label: 'Nieten', size: '4,8x10', type: 'Verz.', unit: 'Stk.' },
        { num: 31, label: 'Nieten', size: '4,8x10', type: 'VA', unit: 'Stk.' },
        { num: 35, label: 'Schrauben', size: '4,8x13', type: 'Verz.', unit: '' },
        { num: 34, label: 'Schrauben', size: '4,8x13', type: 'VA', unit: '' },
        { num: 45, label: 'Schrauben', size: '6,5x13', type: 'Verz.', unit: '' },
        { num: 46, label: 'Schrauben', size: '6,5x13', type: 'VA', unit: '' },
        { num: 39, label: 'Haften', size: '100mm', type: 'AZ', unit: 'Stk.' },
      ];
    },
    consumablesTable2() {
      return [
        { num: 24, label: 'Schlösser klein', size: '55/60', type: 'Verz.', unit: 'Stk.' },
        { num: 22, label: 'Schlösser klein', size: '55/60', type: 'VA', unit: 'Stk.' },
        { num: 25, label: 'Schlösser groß', size: '3,2x8', type: 'Verz.', unit: 'Stk.' },
        { num: 23, label: 'Schlösser groß', size: '4,8x10', type: 'VA', unit: 'Stk.' },
        { num: 37, label: 'Bohrer', size: '3,5 mm', type: '', unit: 'Stk.' },
        { num: 38, label: 'Bohrer', size: '5,1 mm', type: '', unit: 'Stk.' },
        { num: 26, label: 'Kappenbänder', size: '', type: 'Aluzink', unit: 'mm' },
        { num: 27, label: 'Kappenbänder', size: '', type: 'Alu', unit: 'mm' },
        { num: 28, label: 'Kappenbänder', size: '', type: 'Verz.', unit: 'mm' },
        { num: 29, label: 'Kappenbänder', size: '', type: 'VA', unit: 'mm' },
        { num: 47, label: 'Kappenbänder', size: '', type: 'Platal', unit: 'mm' },
      ];
    },
  },
};
</script>
