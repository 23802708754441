<template>
  <div class="container-fluid">
    <b-modal
      ref="edit-order-labor-hrs-modal"
      size="lg"
      :title="$t('orderMsgs.manageLaborHrs')"
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12" v-if="order">
                  <label for="order">{{ $t('orderMsgs.order') }}</label>
                  <input type="text" class="form-control" id="order" :value="order.order_number" disabled />
                </div>

                <div class="form-group col-md-12">
                  <label for="laborHrs">{{ $t('orderMsgs.laborHrs') }}</label>
                  <input
                    class="form-control"
                    :class="!isLaborHrsFormulaValid ? 'is-invalid' : ''"
                    id="laborHrs"
                    :placeholder="$t('orderMsgs.laborHrs')"
                    v-model="laborHrs"
                    required
                    :disabled="isLoading"
                    @blur="sumLaborHrs"
                  />

                  <div class="invalid-feedback" v-if="!isLaborHrsFormulaValid">
                    {{ $t('orderMsgs.laborHrsFormulaFormatInvalid') }}
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <label for="orderHourlyRate">{{ $t('hourlyWageMsgs.hourlyWage') }}</label>
                  <input
                    class="form-control"
                    id="orderHourlyRate"
                    :placeholder="$t('hourlyWageMsgs.emptyForDefRate')"
                    v-model="orderHourlyRate"
                    :disabled="isLoading"
                  />
                </div>
              </div>

              <div class="text-right">
                <b-button type="submit" variant="primary" :disabled="isLoading" style="min-width: 100px">
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{ $t('update') }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { sum } from 'lodash';
import { LABOR_HRS_SUM_REGEX } from '../../common/constants';

export default {
  name: 'EditOrderLaborHrsModal',

  props: {
    showModal: Boolean,
    orderId: Number,
  },

  data() {
    return {
      laborHrs: '0',
      orderHourlyRate: null,
      order: null,

      isLoading: false,
      isLaborHrsFormulaValid: true,
    };
  },

  methods: {
    ...mapActions(['updateOrder', 'getOrder']),

    cleanFloat(value) {
      return parseFloat(value.replace(',', '.'));
    },

    floatToString(value) {
      return value.toString().replace('.', ',');
    },

    sumLaborHrs() {
      this.isLaborHrsFormulaValid = true;
      this.laborHrs = this.laborHrs.replaceAll(' ', '');
      if (LABOR_HRS_SUM_REGEX.test(this.laborHrs)) {
        this.laborHrs = this.floatToString(sum(this.laborHrs.split('+').map((indHrs) => this.cleanFloat(indHrs))));
      } else {
        this.isLaborHrsFormulaValid = false;
      }
    },

    resetData() {
      this.laborHrs = '0';
      this.orderHourlyRate = null;
      this.isLaborHrsFormulaValid = true;
      this.order = null;
      this.isLoading = false;
    },

    hideModal() {
      this.$emit('close');
      this.resetData();
    },

    hideModalManual() {
      this.$refs['edit-order-labor-hrs-modal'].hide();
      this.hideModal();
    },

    hideWithUpdateModal() {
      this.$refs['edit-order-labor-hrs-modal'].hide();
      this.$emit('closeUpdate');
      this.resetData();
    },

    async fetchOrderData() {
      this.isLoading = true;

      try {
        this.order = (await this.getOrder(this.orderId)).data;

        this.laborHrs = this.floatToString(this.order.labor_hours);
        this.orderHourlyRate = this.order.hourly_wage;
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        this.hideModalManual();
      }

      this.isLoading = false;
    },

    async onSubmit() {
      // TODO: Add validations.

      this.isLoading = true;
      if (!this.isLaborHrsFormulaValid) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      } else {
        try {
          await this.updateOrder({
            id: this.orderId,
            data: { labor_hours: this.cleanFloat(this.laborHrs) || 0, hourly_wage: this.orderHourlyRate || null },
          });

          this.makeToast('success', this.$t('orderMsgs.laborHrsUpdated'));
          this.isLoading = false;
          this.hideWithUpdateModal();
        } catch (error) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {
    ...mapGetters(['getCurrLanguage']),
  },

  watch: {
    showModal(value) {
      if (value && this.orderId) {
        this.fetchOrderData();
        this.$refs['edit-order-labor-hrs-modal'].show();
      }
    },
  },
};
</script>
